var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "light_gray", elevation: "0" } },
    [
      _c(
        "div",
        { staticClass: "address-container mb-8" },
        _vm._l(_vm.customerInfo.addresses, function (address, index) {
          return _c(
            "div",
            { key: "changeAddress_" + index, staticClass: "mb-4" },
            [
              _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var hover = ref.hover
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "address-box rounded-lg pa-4 cursor-pointer",
                              class:
                                hover || address.uuid == _vm.selectedAddressId
                                  ? "active"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.selectThisAddress(address.uuid)
                                },
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": "",
                                    justify: "space-between",
                                  },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v(_vm._s(address.display_name))]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-4 pa-0",
                                          attrs: {
                                            text: "",
                                            "min-width": "0",
                                            ripple: false,
                                            width: "auto",
                                            height: "auto",
                                            "min-height": "0",
                                            plain: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editAddress(address)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "unbox_primary--text text-capitalize font-weight-bold text-body-2 cursor-pointer",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("label.edit"))
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", [
                                _vm._v(
                                  "(" +
                                    _vm._s(address.phone_code) +
                                    ")" +
                                    _vm._s(address.phone_no)
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(address.address_1) +
                                    " " +
                                    _vm._s(address.address_2) +
                                    " " +
                                    _vm._s(address.address_3)
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(address.city) +
                                    " " +
                                    _vm._s(address.postcode) +
                                    " " +
                                    _vm._s(address.state)
                                ),
                              ]),
                              _c("div", { staticClass: "mb-2" }),
                              address.is_default_billing
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "success_01--text text-caption",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("label.defaultBillingAddress")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              address.is_default_shipping
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "success_01--text text-caption",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("label.defaultShippingAddress")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "v-btn",
        {
          staticClass:
            "rounded-lg font-weight-bold text-body-1 py-6 white--text",
          attrs: { block: "", color: "black" },
          on: { click: _vm.openAddAddressDialog },
        },
        [_vm._v(" " + _vm._s(_vm.$t("label.addAddress")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }